<template>
    <section class="gardenManagementPage">
        <g-breadcrumb bread="添加视频" />

        <div class="dis-flex flex-x-start">
            <div class="left dis-flex flex-dir-column flex-x-center flex-y-center">

                <div class="left_add p-r letter-spacing" :class="rootPath == '' ? 'bg-f' : 'bg-0'">
                    <!-- 1111 -->
                    <div class="left_add_icon p-a dis-flex flex-dir-column flex-x-center flex-y-center">
                        <div class="f-28 col-9" v-if="rootPath == ''">
                            <uploadVideo :limit="limit" @fileList="videoFileClick" :file-list.sync="videoFileList">
                            </uploadVideo>
                        </div>
                        <div v-else>
                            <video :src="rootPath" poster="" type="video/mp4,audio/mp3" controls="controls" preload="auto"
                                style="display: block;width: 280px; height: 200px;">
                                您的浏览器不支持视频播放
                            </video>
                        </div>

                        <p class="col-9" style="line-height:20px;" v-if="rootPath == ''">
                            上传时长2小时内,大小不超过100M,建议分辨率720p及以上,码率10Mbps以内,格式为MP4/H.264格式</p>
                            
                    </div>
                    
                
                </div>
                <el-button class="del-button" v-show="rootPath !== ''" @click="delVideo">删除</el-button>
            </div>
        
            


            <el-form ref="ref_themeForm" @submit.native.prevent>
                <div class="dis-flex flex-x-start flex-y-start" v-show="imageUrl == '' && rootPath !== ''">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="封面预览" label-width="100px">
                        <uploadPics :limit="limit" @fileList="imageFileClick" :file-list.sync="imageFileList"></uploadPics>
                    </el-form-item>
                </div>
                <div class="dis-flex flex-x-start flex-y-start" v-show="imageUrl !== ''">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="封面预览" label-width="100px">
                        <div class="dis-flex flex-dir-column flex-x-start flex-y-start" style="width:200px">
                            <span style="font-size:10px;">视频封面(16:9)</span>
                            <!-- <img :src="imageUrl" width="200" height="100" /> -->
                            <el-image class="my-img" :src="imageUrl" :preview-src-list="imageUrlArr"
                                style="width:200px;height:100px;" @click="vbs(imageUrl)">
                            </el-image>

                            <el-button class="del-button m-top10" style="width:100px" @click="delImage">更换封面</el-button>
                        </div>
                    </el-form-item>
                    

                </div>
                <div class="dis-flex flex-x-start flex-y-start">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="视频标题" label-width="100px">
                        <el-input v-model="videoTitle" maxlength="35" placeholder="请输入视频标题" style="width: 300px;" />
                    </el-form-item>
                </div>
                <div class="dis-flex flex-x-start flex-y-start">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="视频描述" label-width="100px">
                        <Tinymce :height="200" v-model="tiny.value"></Tinymce>
                    </el-form-item>
                </div>
                <div class="dis-flex flex-x-start flex-y-start" v-show="partName !== ''">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="所属部门" label-width="100px">
                        <span>{{ organizationName }}</span>
                    </el-form-item>
                </div>
                <div class="dis-flex flex-x-start flex-y-start">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="所属分类" label-width="100px">
                        <el-select v-model="partName" placeholder="请选择分类" @change="(val) => partChangeType(val)" filterable
                            allow-create default-first-option>
                            <el-option v-for="(item, index) in typeList" :key="index" :label="item.catName"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                </div>

                <div class="dis-flex flex-x-start flex-y-start">
                    <p class="m-top15 col-red">*</p>
                    <el-form-item label="上架状态" label-width="100px">
                        <el-radio v-model="radio" label="0">上架</el-radio>
                        <el-radio v-model="radio" label="1">下架</el-radio>
                    </el-form-item>
                </div>

                <el-button  @click="cancelClicks">取消</el-button>
                <el-button type="primary" @click="buttonClick">提交</el-button>
                
             

            </el-form>
        </div>
        


    






    </section>
</template>

<script>
import Tinymce from "./tinymce";
import uploadVideo from '@/components/Upload/uploadVideo.vue'
import uploadPics from '@/components/Upload/uploadPics.vue'
import {
    // bxUser,
    addPxNotice,
    mp4ToJpg,
    queryPxCat,
    addPxVideo,
    queryPxVideo,
    updatePxVideo

} from '@api/bx/api_contentManagement';
// import UeditorWrap from "vue-ueditor-wrap";
export default {
    components: { Tinymce, uploadVideo, uploadPics },
    data() {

        return {
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },
            total: 0,
            tableData: [],
            typeList: [],
            dicCode: null,
            addCode: null,
            addItem: {},
            dicName: '',
            isEditor: false,
            editorRow: {},
            // parmas: {
            //     zhName: '',//中文名称
            //     enName: '',//英文名称
            //     materialHistory: '',//原料含量
            //     waterCosmetics: '',//复配百分比
            //     residencyCosmetics: '',//实际成分含量
            //     saltRule: '',//使用目的
            //     no: '',//原料序号
            //     saltRule: '',//化妆品安全技术规范位置
            // },
            text: '',
            myConfig: {
                autoHeightEnabled: false, // 编辑器不自动被内容撑高
                initialFrameHeight: 500, // 初始容器高度
                initialFrameWidth: '100%', // 初始容器宽度
                UEDITOR_HOME_URL: '/UEditor/',
                serverUrl: ''
            },
            dishList: [],
            radio: '0',
            dishName: '',
            videoFileList: [],
            imageFileList: [],
            rootPath: "",
            imageUrl: '',
            limit: 1,
            partName: '',
            partItem: {},
            videoTitle: '',
            organizationName: '',
            organizationId: '',
            imageUrlArr: []
        }
    },
    created() {
        this.editor = this.$route.query.editor
        this.id = this.$route.query.id
        this.queryPxCat()
        if (this.editor == '1') {
            this.queryPxVideo()
        }

    },
    methods: {
        vbs(val) {
            this.imageUrlArr = []
            this.imageUrlArr.push(val)
        },
        delVideo() {
            this.rootPath = ''
        },
        delImage() {
            this.imageUrl = ''
        },
        videoFileClick(msg) {
            this.rootPath = msg.rootPath
            mp4ToJpg({
                videoUrl: this.rootPath

            }).then(res => {
                console.log(res)
                this.imageUrl = res

            })
        },
        imageFileClick(msg) {
            console.log(msg)
            this.imageUrl = msg.rootPath

        },


        // 视频分类筛选
        queryPxCat() {
            queryPxCat({}).then(res => {
                this.typeList = res
                // this.total = res.total

            })
        },
        partChangeType() {
            // console.log(this.partName)
            this.typeList.map((item, index) => {
                if (this.partName == item.id) {
                    this.organizationName = item.organizationName
                    this.organizationId = item.organizationId


                }

            });
        },
        // handleSuccess(val) {
        //     console.log(val)
        // },
        // 保存富文本内容-保存字符串
        add() {
            const params = `this.tiny?.value`
        },
        edit() {
            // 接口回显保存html
            // this.tiny.value = res.data|| ''
        },
        changeType() { },
        buttonClick() {
            //首次提交
            addPxNotice({
                noticeTitle: '测试',
                noticeContent: this.tiny.value,
                noticeImg: ''

            }).then(res => {
                this.$message.success('上传成功')

            })
            //编辑提交
            // updatePxNotice({
            //     noticeTitle: '编辑测试',
            //     noticeContent: this.tiny.value,
            //     id: 836,
            //     noticeImg: ''

            // }).then(res => {
            //     this.$message.success('上传成功')

            // })
            // console.log(this.tiny.value)


        },
        cancelClicks(){
            // console.log('1111111')
            this.$router.push('/bxPxVideoMenu/dcPxVideoList')
            
        },
        //提交
        buttonClick() {
            if (this.rootPath == '') {
                this.$message.warning('请上传视频')
                return
            }
            if (this.imageUrl == '') {
                this.$message.warning('请上传封面图片')
                return
            }
            if (this.videoTitle == '') {
                this.$message.warning('请填写视频标题')
                return
            }
            if (this.tiny.value == undefined) {
                this.$message.warning('请填写视频描述')
                return
            }
            if (this.partName == '') {
                this.$message.warning('请选择所属分类')
                return
            }
            if (this.catInfo == '') {
                this.$message.warning('请填写分类简介')
                return
            }



            if (this.editor == 0) {
                addPxVideo({
                    videoTitle: this.videoTitle,
                    videoInfo: this.tiny.value,
                    organizationId: this.organizationId,
                    catId: this.partName,
                    videoImg: this.imageUrl,
                    videoUrl: this.rootPath,
                    videoState: this.radio
                }).then(res => {
                    console.log(res)
                    this.$message.success('新增成功')
                    this.$router.push('/bxPxVideoMenu/dcPxVideoList')
                })
            } else {
                updatePxVideo({
                    id: this.id,
                    videoTitle: this.videoTitle,
                    videoInfo: this.tiny.value,
                    organizationId: this.organizationId,
                    catId: this.partName,
                    videoImg: this.imageUrl,
                    videoUrl: this.rootPath,
                    videoState: this.radio
                }).then(res => {
                    console.log(res)
                    this.$message.success('修改成功')
                    this.$router.push('/bxPxVideoMenu/dcPxVideoList')
                })
            }

        },
        //文章复现
        queryPxVideo() {
            queryPxVideo({
                id: this.id
            }).then(res => {
                this.videoTitle = res[0].videoTitle
                this.tiny.value = res[0].videoInfo
                this.radio = res[0].isCarousel
                this.rootPath = res[0].videoUrl
                this.imageUrl = res[0].videoImg
                this.partName = res[0].catId
                this.radio = res[0].videoState
                this.organizationName = res[0].organizationName


            })
            // console.log(this.tiny.value)
        },



        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

        // 会员管理列表
        // bxUser() {
        //     bxUser(this.searchForm).then(res => {
        //         this.tableData = res.records
        //         this.total = res.total

        //     })
        // },

        //解绑
        // dissolutionFormClick(row) {
        //     this.$confirm('是否脱离关系?', '提示', {
        //         confirmButtonText: '脱离关系',
        //         cancelButtonText: '关闭',
        //         type: 'warning'
        //     }).then(() => {
        //         bxUserUnbinding({ userId: row.userId }).then(res => {
        //             this.$message.success('解绑成功')
        //             this.bxUser()
        //         })
        //     })
        // },
        //表格删除
        delteFormClick(row) {
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // cfdelCosmeticsMaterial({ batchMonomer: row.batchMonomer }).then(res => {
                // 	this.cfqueryCosmeticsMaterial()
                // })
            })

        }

    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}

.left {
    min-width: 350px;
    max-width: 350px;
    margin-right: 60px;

    .del-button {
        width: 100px;
        margin-top: 10px;
    }

    .left_add {
        width: 100%;
        height: 600px;
        border: 1px solid #ccc;
        border-radius: 8px;


        .left_add_icon {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
        }
    }
}
</style>

