import { render, staticRenderFns } from "./addVideo.vue?vue&type=template&id=205e86f1&scoped=true&"
import script from "./addVideo.vue?vue&type=script&lang=js&"
export * from "./addVideo.vue?vue&type=script&lang=js&"
import style0 from "./addVideo.vue?vue&type=style&index=0&id=205e86f1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205e86f1",
  null
  
)

export default component.exports